


























































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Logo74',
})
export default class Logo74 extends Vue {}
